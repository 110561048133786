.snapshot {

}

.snapshot .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.snapshot .day {
    font-size: 1.2em;
}

.snapshot .date .day-time {
    display: flex;
}

.snapshot .date .month-date {
    font-size: 1.2em;
}

.snapshot .date .time {
    margin-left: .5em;
    padding-left: .5em;
    border-left: 1px solid black;
    display: flex;
    align-items: center;
}

.snapshot .data {
    display: flex;
    border-top: 1px solid black;
    margin-top: 1em;
    padding-top: .5em;
    justify-content: space-between;
}

.snapshot .left {
    width: 50%;
    text-align: center;
}

.snapshot .right {
    
}

.snapshot .temperature {
    font-size: 2em;
    padding: .5em;
}

.snapshot .humidity {
    display: flex;
}

.snapshot .humidity .label,
.snapshot .wind .label {
    
}

.snapshot .humidity .value {

}

.snapshot .description {
    display: flex;
    align-items: center;
}

.snapshot .description .label {
    font-size: 1.5em;
}


.snapshot .wind .direction {

}

.snapshot .wind .gusts {

}

