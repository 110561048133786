.hourly {

}

.hourly .hour .row {
    display: flex;
}

.hourly .hour .row .label {
    width: 30%;
    text-align: right;
    margin-right: 9%;
}

.hourly .hour .row .temp {
    width: 20%;
    text-align: center;
    margin: 0 5%;
}

.hourly .hour .row .icon img {
    height: 25px
}

.hourly .hour .row .icon {
    /* width: 20%;
    text-align: left */
}



/* .chart-container {
    display: flex;
    justify-content: center;
}

.lineup {
    display: flex;
    justify-content: space-around;
    font-weight: 200;
}

.lineup > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lineup .high,
.lineup .low {
    font-size: 1.5em;
}

.lineup .high {
    text-shadow: 1px 1px #ec6e4b
}
.lineup .low {
    text-shadow: 1px 1px #4b92ec;
}

.lineup .high::after,
.lineup .low::after {
    content: '°';
} */