.app {
    display: flex;
    flex-direction: column;
    align-items: center;
}

body {
    background: #def;
    background-image: url(/blue-clouds.jpeg);
}

header,
main {
    display: flex;
    margin-bottom: 2em;
}

main {
    flex-direction: column;
}

header a {
    background: #eee;
    display: flex;
    padding: .3em;
    min-width: 20vw;
    justify-content: center;
    border-radius: 0 0 5px 5px;
    margin: 0 .2em;
    font-weight: 200;
    font-size: 1em;
    box-shadow: 3px 3px 6px black;

}

button {
    border-width: 1px;
    border-radius: 5px;
    box-shadow: 3px 3px 6px black;
}

a {
    text-decoration: none;
    color: black;
    border-style: solid;
    border-color: #eee;
    border-width: 0 1px 2px;
}

section {
    width: 90vw;
    background: #eee;
    box-shadow: 3px 3px 6px black;
    border-radius: 5px;
    padding: 1em;
    font-weight: 200;
}

section h1 {
    font-weight: 200;
    margin-top: 0;
}
section h2 {
    font-weight: 200;
    margin-top: 0;
    margin-bottom: 0;
}

.location-for-data {
    margin-bottom: 1em;
    padding-top: .3em;
    padding-bottom: .3em;
}

.icon {
    background: #666;
    background: radial-gradient(#666, #aaa, transparent, transparent);
}

.icon img {
    height: 50px;
}
